




























































































import "vuex";
import { Component } from "vue-property-decorator";
import Vue from "@/vue-ext";
import validator from "validator";
import { TranslateResult } from "vue-i18n";
import { getModule } from "vuex-module-decorators";
import SignupStoreModule from "../../store/modules/signup";
import SignupApi, { SUPPORTED_REGIONS } from "../../services/signup-api";
import { SignupResult } from "../../models/signup-result";
import { HouseholdMember } from "@/models/household-member";
import moment from "moment";
import PhoneInput from '../common/phone-input.vue';
import { isValidPhoneNumber } from 'libphonenumber-js';

@Component({
  data() {
    return {
      showPassword: false,
    };
  },
  components: {
    PhoneInput
  }
})
export default class BasicInfoSection extends Vue {
  store: SignupStoreModule = getModule(SignupStoreModule, this.$store);
  members: HouseholdMember[] = [];
  email = "";
  password = "";
  duplicateEmail = false;

  processing = false;

  get selectedRegion() {
    return {
      indexes: [0],
      value: [SUPPORTED_REGIONS[this.store.signupState.region]]
    };
  }

  get supportedRegions() {
    const region = this.store.signupState.region;
    return Object.entries(SUPPORTED_REGIONS)
      .filter(([value, label]) => value === region)
      .map(([value, label]) => ({ label, value }))
  }

  created(): void {
    const cache = this.store.signupState;
    this.email = cache.email;
    this.password = cache.password;
    this.members = cache.members;

    if (this.members.length === 0) {
      // Add adults to our member list
      for (let index = 1; index <= this.numberOfAdults; index++) {
        this.members.push({
          index,
          head: index === 1,
          adult: true,
          firstname: "",
          middlename: "",
          lastname: "",
          birthdate: undefined,
          phone: "",
        });
      }

      // Add children to our member list
      for (let index = 1; index <= this.numberOfChildren; index++) {
        this.members.push({
          index,
          head: false,
          adult: false,
          firstname: "",
          middlename: "",
          lastname: "",
          birthdate: undefined,
          phone: "",
        });
      }
    }
  }

  async submit(): Promise<void> {
    try {
      this.store.setConfirmedSignup(false);
      this.duplicateEmail = false;
      this.processing = true;
      const info = {
        members: this.members,
        email: this.email,
        password: this.password,
        invitationCode: this.$route.query.code
          ? (this.$route.query.code as string)
          : undefined,
        region: this.store.signupState.region,
      };

      const result: SignupResult = await SignupApi.createAccount(info);
      this.store.setConfirmedSignup(result.userConfirmed);
      this.store.setConfirmationMethod(result.confirmationMethod);
      this.store.saveBasicInfo(info);
      this.$emit("next");
    } catch (ex: any) {
      if (ex.code === "USERNAME_EXISTS") {
        this.duplicateEmail = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } finally {
      this.processing = false;
    }
  }

  get complete(): boolean {
    return (
      this.hasValidEmail && this.hasValidPassword && this.allHasRequiredFields
    );
  }

  get hasValidEmail(): boolean {
    return validator.isEmail(this.email);
  }

  get hasValidPassword(): boolean {
    return validator.isStrongPassword(this.password);
  }

  get corporateAccountFlow(): boolean {
    return this.$route.params.type === "corporate";
  }

  get allHasRequiredFields(): boolean {
    return !this.members.find((member) => {
      return (
        member.firstname.trim() === "" ||
        member.lastname.trim() === "" ||
        !member.birthdate ||
        !(
          member.birthdate instanceof Date ||
          moment(member.birthdate, "DD / MM / YYYY").isValid()
        ) ||
        ((member.head || member.phone) &&
          !this.isValidMobilePhone(member.phone || ""))
      );
    });
  }

  get today(): Date {
    return new Date();
  }

  requiredRules(message: TranslateResult): (v: string) => TranslateResult {
    return (v: string) => {
      if (!v) return message;

      return "";
    };
  }

  validEmailRules(v: string): TranslateResult {
    if (!v) return this.$t("Email address is required.");

    if (!validator.isEmail(v)) return this.$t("Email address is not valid.");

    return "";
  }

  get numberOfAdults(): number {
    return Number(this.$route.query.adults) || 1;
  }

  get numberOfChildren(): number {
    return Number(this.$route.query.children);
  }

  validPhoneRules(v: string): TranslateResult {
    if (v) {
      if (!this.isValidMobilePhone(v) || !v.startsWith("+"))
        return this.$t("Phone number is not valid.");
    }

    return "";
  }

  validAndRequiredPhoneRules(v: string): TranslateResult {
    if (!v) return this.$t("Phone number is required.");

    if (!this.isValidMobilePhone(v) || !v.startsWith("+"))
      return this.$t("Phone number is not valid.");

    return "";
  }

  validPasswordRules(v: string): TranslateResult {
    if (!v) return this.$t("Password is required.");

    if (!validator.isStrongPassword(v))
      return this.$t(
        "Password must consist of at least 8 characters, one lower case letter, one capital letter, one number and one symbol."
      );

    return "";
  }

  getLabel(member: HouseholdMember): string {
    const name = [member.firstname, member.lastname].join(" ").trim();
    const description = member.head ? this.$t("- Account holder") : "";
    let type = member.adult ? this.$t("Adult") : this.$t("Child");

    return name
      ? `${name} ${description}`
      : `${type} ${member.index} ${description}`;
  }

  isValidMobilePhone(number: string): boolean {
    if (number.startsWith("+") && isValidPhoneNumber(number)) {
      return true;
    }
    return false;
  }

  getAdultAgeDate(): Date {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today;
  }
}
