





















import { Component, Prop, Vue } from "vue-property-decorator";
import { CreateElement, RenderContext } from "vue";

@Component<Wizard>({
  components: {
    contentRenderer: {
      functional: true,
      render: (_: CreateElement, ctx: RenderContext) => {
        return ctx.props.node;
      },
    },
  },

  props: {
    contents: {
      type: Array,
      default: () => [],
    },
  },
})
export default class Wizard extends Vue {
  @Prop({ type: Number, required: true })
  value!: number;

  moveToStep(step: number): void {
    if (this.value > step) {
      this.$emit("input", step);
    }
  }
}
