
























import { Component } from "vue-property-decorator";
import Vue from "@/vue-ext";
import Layout from "../components/layouts/signup-layout.vue";
import BasicInfoSection from "../components/sections/basic-info-section.vue";
import UserAgreementSection from "../components/sections/user-agreement-section.vue";
import AccountVerificationSection from "../components/sections/account-verification-section.vue";
import PaymentSection from "../components/sections/payment-section.vue";
import { Section } from "../models/section";
import SignupStoreModule from "../store/modules/signup";
import { getModule } from "vuex-module-decorators";

@Component<SignupPage>({
  components: {
    Layout,
    BasicInfoSection,
    UserAgreementSection,
    AccountVerificationSection,
    PaymentSection,
  },
})
export default class SignupPage extends Vue {
  store: SignupStoreModule = getModule(SignupStoreModule, this.$store);
  showCancelConfirmationDialog = false;

  get sections(): Section[] {
    let steps = [
      { component: "BasicInfoSection", label: this.$t("Basic information") },
      {
        component: "AccountVerificationSection",
        label: this.$t("Account verification"),
      },
      { component: "UserAgreementSection", label: this.$t("User agreement") },
    ];

    if (!this.isCorporateAccountFlow) {
      steps.push({ component: "PaymentSection", label: this.$t("Payment") });
    }

    return steps;
  }

  get section(): number {
    const currentSection = Number(this.$route.params.section);
    return Number(currentSection - 1);
  }

  set section(newSection: number) {
    this.store.setCompletedSteps(newSection);
    this.$router.push({
      query: this.$route.query,
      params: {
        section: `${newSection + 1}`,
      },
    });
  }

  next(): void {
    this.section++;
  }

  previous(): void {
    this.section--;
  }

  cancel(): void {
    if (this.isCorporateAccountFlow) {
      this.$router.replace({
        name: "invitation",
        params: {
          code: this.$route.query.code as string,
        },
      });
    } else {
      this.$router.replace({
        name: "choose-plan",
      });
    }
  }

  complete(): void {
    this.$router.push(
      {
        name: "complete",
        query: {
          email: this.store.signupState.email,
          corporate: String(this.isCorporateAccountFlow),
          success: String(true),
        },
      },
      () => {
        location.reload();
      }
    );
    this.store.clearState();
  }

  get isCorporateAccountFlow(): boolean {
    return this.$route.params.type === "corporate";
  }
}
