













import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component<CodeInputField>({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
  },
})
export default class CodeInputField extends Vue {
  @Prop({ type: String, required: true })
  value = "";

  @Prop({ type: Number, default: 6 })
  size = 6;

  onKeypress(index: number): void {
    setTimeout(() => {
      let newValue = "";
      for (let i = 1; i <= this.size; i++) {
        newValue += this.getInputFieldByIndex(i)?.value.trim();
      }

      this.selectInput(index + 1);
      this.$emit("input", newValue);
    });
  }

  @Watch("value")
  onValueChange(value: string): void {
    const index = value.length;
    const inputField = this.getInputFieldByIndex(index + 1);
    if (inputField) {
      setTimeout(() => inputField.focus());
    }
  }

  selectInput(index: number): void {
    const inputFlied = this.getInputFieldByIndex(index);
    if (inputFlied) inputFlied.select();
  }

  private getInputFieldByIndex(index: number): HTMLInputElement | null {
    const match: HTMLInputElement[] = this.$refs[
      `input-${index}`
    ] as HTMLInputElement[];
    return match ? match[0] : null;
  }
}
