























import { Component, Vue } from "vue-property-decorator";
import List from "@/components/common/list.vue";
import { TranslateResult } from "vue-i18n";
import SignupApi from "@/services/signup-api";
import { getModule } from "vuex-module-decorators";
import SignupStoreModule from "../../store/modules/signup";
import { Subscription } from "@/models/subscription";

const ANNUAL_CYCLE = "annual";
const MONTHLY_CYCLE = "monthly";
const WEEKLY_CYCLE = "weekly";

@Component({
  components: {
    List,
  },

  data() {
    return {
      subscription: null,
    };
  },
})
export default class PaymentSection extends Vue {
  store: SignupStoreModule = getModule(SignupStoreModule, this.$store);
  subscription!: Subscription;
  processing = false;

  async created(): Promise<void> {
    this.subscription = await SignupApi.calculatePlan({
      adults: this.numberOfAdults,
      children: this.numberOfChildren,
      startDate: this.subscriptionStartDate,
      endDate: this.subscriptionEndDate,
      type: this.subscriptionType,
    });
  }

  get numberOfAdults(): number {
    return this.$route.query.adults ? Number(this.$route.query.adults) : 1;
  }

  get numberOfChildren(): number {
    return this.$route.query.children ? Number(this.$route.query.children) : 0;
  }

  get subscriptionType(): "annual" | "custom" | "corporate" {
    return this.$route.params.type as "annual" | "custom" | "corporate";
  }

  get subscriptionStartDate(): number {
    return Number(this.$route.query.start);
  }

  get subscriptionEndDate(): number {
    return Number(this.$route.query.end);
  }

  get total(): string {
    return Number(this.subscription.total).toFixed(2);
  }

  get subscriptionCoverage(): TranslateResult {
    const { cycle, interval } = this.subscription?.plan;

    switch (interval) {
      case ANNUAL_CYCLE:
        return cycle === 1
          ? this.$t("Coverage for 1 year")
          : this.$t("Coverage for {cycle} years", { cycle });
      case MONTHLY_CYCLE:
        return cycle === 1
          ? this.$t("Coverage for 1 month")
          : this.$t("Coverage for {cycle} months", { cycle });
      case WEEKLY_CYCLE:
        return cycle === 1
          ? this.$t("Coverage for 1 week")
          : this.$t("Coverage for {cycle} weeks", { cycle });

      default:
        return "";
    }
  }

  get summary(): TranslateResult[] {
    return [
      this.subscriptionCoverage,
      this.$t("Coverage for {count} person(s)", {
        count: this.numberOfAdults + this.numberOfChildren,
      }),
      this.$t("Payment auto-renewal {onoff}", {
        onoff: this.subscription?.recurring ? this.$t("on") : this.$t("off"),
      }),
    ];
  }

  async checkout(): Promise<void> {
    this.processing = true;

    try {
      const email = this.store.signupState.email;
      const result = await SignupApi.checkout(email, {
        adults: this.numberOfAdults,
        children: this.numberOfChildren,
        startDate: this.subscriptionStartDate,
        endDate: this.subscriptionEndDate,
        type: this.subscriptionType,
        successRedirectUrl: `${window.location.origin}#/signup/complete?email=${email}`,
      }, this.store.signupState.region);

      if (result && result.paymentUrl) {
        location.href = result.paymentUrl;
      }
    } catch (ex) {
      console.error(ex);
      this.processing = false;
    }
  }
}
