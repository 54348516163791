



































import { Component, Ref } from "vue-property-decorator";
import Vue from "@/vue-ext";
import { getCountryCallingCode, getCountries } from "libphonenumber-js";
import { cloneDeep } from 'lodash';
// import Component from 'vue-class-component';

type Country = { label: string; value: string; code: string };

@Component({
  data() {
    return {
      $nlInput: {} as (HTMLElement & { _input: HTMLInputElement }),
      searching: false,
      filled: false,
      isScrollingList: false,
      iconCountry: null,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    validate: {
      type: Function,
      default: () => "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cloneDeep,
  }
})
export default class PhoneInput extends Vue {
  @Ref() $nlInput!: HTMLElement & { _input: HTMLInputElement };

  searching = false;
  filled = false;
  isScrollingList = false;
  iconCountry: string | null = null;

  mounted() {
    this.$nlInput!.addEventListener("focus", () => {
      this.searching = true;
    });
  }

  get countries(): Country[] {
    return getCountries()
      .map((country) => ({
        label: `${getCountryName(country)} (+${getCountryCallingCode(country)})`,
        value: getCountryCallingCode(country),
        code: country
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  get filteredCountries(): Country[] {
    if (!this.$props.value) {
      return this.countries;
    }
    return this.countries.filter(
      (country) => country.label.toLowerCase()
        .includes(this.$props.value.toLowerCase())
    );
  }

  onSelectCountry(event: CustomEvent, country: Country) {
    console.log(country);
    if (this.isScrollingList === true) {
      return;
    }
    if (country === null) {
      this.iconCountry = null;
      this.$emit("value-change", new CustomEvent("value-change", {
        detail: {
          value: ""
        }
      }));
      this.searching = false;
      this.filled = false;
      return;
    }
    this.iconCountry = country.code;
    this.$emit("value-change", new CustomEvent("value-change", {
      detail: {
        value: "+" + country.value
      }
    }));
    this.searching = false;
    this.filled = true;
    setTimeout(() => {
      this.$nlInput?._input?.focus();
    }, 100);
    event.preventDefault();
  }

  onValueChange(event: CustomEvent<{ value: string }>) {
    const value = event.detail.value;

    if (!value) {
      this.iconCountry = null;
      this.filled = false;
      this.$emit("value-change", event);
      return;
    }

    this.$emit("value-change", event);
    this.searching = true;
  }
}

function getCountryName(countryCode: string) {
  const regionNamesInEnglish = new (Intl as any).DisplayNames(["en"], {
    type: "region"
  });

  return regionNamesInEnglish.of(countryCode);
}
