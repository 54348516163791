















import { Component, Prop, Vue } from "vue-property-decorator";
import SignupWizard from "../common/wizard.vue";

@Component<SignupLayout>({
  components: {
    SignupWizard,
  },

  props: {
    nextDisabled: {
      type: Boolean,
      default: false,
    },

    previousDisabled: {
      type: Boolean,
      default: false,
    },

    nextLabel: {
      type: String,
      default: null,
    },

    previousLabel: {
      type: String,
      default: null,
    },
  },
})
export default class SignupLayout extends Vue {
  appVersion = process.env.VUE_APP_VERSION;

  @Prop({
    type: Number,
    required: true,
  })
  section!: number;

  get wizardStatus(): number {
    return this.section;
  }

  set wizardStatus(section: number) {
    this.$emit("sectionChange", section);
  }
}
