







import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    items: {
      type: Array,
      required: true,
    },

    endless: {
      type: Boolean,
      default: false,
    },
  },
})
export default class List extends Vue {}
