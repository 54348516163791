


















import { Component, Prop, Vue } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import SignupStoreModule from "../../store/modules/signup";
import { getModule } from "vuex-module-decorators";
import SignupApi from "../../services/signup-api";
import { ActivateAccountResult } from "../../models/activate-account-result";

@Component({})
export default class UserAgreementSection extends Vue {
  store: SignupStoreModule = getModule(SignupStoreModule, this.$store);
  processing = false;

  @Prop({ type: Boolean })
  error!: boolean;

  get content(): TranslateResult {
    return this.$t(`
          <h2>Mobile application terms & conditions</h2><br/>

          <strong>Introduction</strong><br/>
          Aware24 Ltd. Mobile Application provides you with assistance solutions in case of emergency medical situations. Our mobile application is meant to fill the gap between the moment you need assistance and the moment your insurance takes over, which can take precious hours away. <br/>
          Our duty is to provide you with timely last mile emergency medical solutions, being sending you an ambulance, emergency evacuation, directing you to the right hospital & etc. If you wish to use the application alongside with a health insurance, we’ll deal with your insurance directly and you won’t have to cover any expenses. Should you register to our Mobile Application without having insurance, some services rendered through the application (ambulance dispatch, medical expenses & etc.) will be charged back to you by the medical providers you’ll use. <br/>
          <br/>

          <strong>Who we are</strong><br/>
          Aware24 is a network of medical assistance & repatriation companies with headquarters in Singapore, all of which have a physical presence in their respective countries. When pressing the emergency or concierge button, you’ll reach Aware24 local call center in the country you specified in the settings. This mobile application is provided to you by Aware24 Ltd., an assistance company registered in Hong-Kong and affiliated to Aware24. The application is developed jointly with Newlogic, a digital consultancy company, headquartered in Singapore. <br/><br/>
          By clicking the "agree" button, downloading, installing and using the mobile application you acknowledge that you have read, understood and accepted this agreement; and agree that you are legally bound by its terms and conditions. Should you disagree to these terms, you can delete this application from your mobile device.<br/>
          <br/>

          <strong>Conflicts of interest</strong><br/>
          Our aim is to be able to take care of patients as early as possible, in order to provide the most efficient emergency assistance possible, and to avoid delays that can be life threatening. Therefore, our goal is to provide you with the best care at the closest health center possible. We declare that whenever we need to dispatch an ambulance, or to refer a patient to a hospital, we’ll do so for the sake of patient’s best interest. We do not have any conflict of interest with any local hospitals or healthcare providers. You’re safe with us. Also, for each service provided to you (ambulance transfer, house call doctor...), it will be charged to you at cost price in case you don’t have any insurance. <br/>
          <br/>

          <strong>Use of location services</strong><br/>
          After registration, you’ll be asked to choose the country where you reside or the country where you’re travelling to. When you press the emergency button of the application, our local call center will immediately receive your GPS location and medical information. Please note that if you travel to another country, you’ll need to manually change the country in the “Account profile” field in the application setting. If you forget to change the country and press the emergency or concierge buttons, notifications will be sent to the wrong call center, resulting in delays in providing you with assistance. <br/>
          <br/>

          <strong>Terms and Conditions</strong><br/>
          This mobile application terms and conditions ("Terms") are a binding agreement between you ("End User" or "you") and Aware24 Ltd.  (“The Service provider" “We”). This agreement governs your use of the mobile application services, registered under a membership subscribed by you, your employer or your organization (“Subscriber” “You”) between the Subscriber and Aware24 Ltd.. These Terms may be modified or updated by Aware24 Ltd. from time to time and are effective upon posting an updated version of the application. We will send You a notification via the application. Continued use of the application shall constitute Your consent to such changes.<br/>
          By clicking the "agree" button, downloading, installing and using the mobile services you acknowledge that you have read and understand this agreement and accept this agreement and are legally bound by its terms.<br/>
          <br/>

          <ol>
            <li>
              License Grant.<br/>
              In the framework of this Agreement, Aware24 Ltd. grants you a limited, non-exclusive, and non-transferable license to use the application for your personal, non-commercial use on a single mobile device owned or otherwise controlled by you.
              <br/><br/>
            </li>
            <li>
              License Restrictions. Licensee shall not:<br/>
              <ol type="a">
                <li>Copy the Application;</li>
                <li>Reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the application or any part thereof;</li>
                <li>Modify, adapt, or create derivative works or improvements, whether or not patentable, of the application;</li>
                <li>Remove, alter or delete, any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the application;</li>
                <li>Rent, lease, lend, sell, distribute, transfer, or make available the application, or any features or functionality of the application, to any third party for any reason, including by making the application available on a network where it is capable of being accessed by more than one device at any time;</li>
                <li>Remove, disable, circumvent, or create or implement any workaround to any copy protection, rights management, or security features in or protecting the application;</li>
                <li>Attempt to probe, scan or test the vulnerability of any system or network or breach any security or authentication measures;</li>
                <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
              </ol>
              <br/>
            </li>

            <li>
              Reservation of Rights.<br/>
              You acknowledge and agree that the application is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the application in accordance with the license granted, and subject to all terms, conditions, and restrictions, under this Agreement. Aware24 Ltd. application reserves and shall retain its entire right, title, and interest in and to the application, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.
              <br/><br/>
            </li>

            <li>
              Connectivity Costs and Services.<br/>
              The Mobile Services provided by Aware24 Ltd. application will generate mobile data usage.  Aware24 Ltd. application highly recommends that you subscribe to a “data plan” through your mobile carrier.  “International roaming” may increase these fees.  We therefore strongly recommend that you purchase a local sim card wherever you travel.
              <br/><br/>
            </li>

            <li>
              Collection and Use of Your Information.<br/>
              Please visit <a href='https://resources.aware24-app.com/privacy-policy/index.html'>https://resources.aware24-app.com/privacy-policy/index.html</a>
              <br/><br/>
            </li>

            <li>
              Updates.<br/>
              Aware24 Ltd. application may from time to time in its sole discretion develop and provide application updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, "Updates"). Based on your mobile device settings, when your mobile device is connected to the internet either:

              <ol type="a">
                <li>The application will automatically download and install all available updates; or</li>
                <li>You may receive notice of or be prompted to download and install available updates.</li>
              </ol>
              You shall promptly download and install all updates and acknowledge and agree that the application or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the application and be subject to the Terms.
              <br/><br/>
            </li>

            <li>
              Term and Termination.<br/>
              <ol>
                <li>The term of Agreement commences when you download the application and will continue in effect until terminated by you or Aware24 Ltd. Application.</li>
                <li>You may terminate this Agreement by deleting the application and all copies thereof from your mobile device.</li>
                <li>Aware24 Ltd. application may terminate this Agreement at any time without notice if it ceases to support the application, which Aware24 Ltd. application may do in its sole discretion or upon termination of the Membership Agreement between Aware24 Ltd. application and the Subscriber. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.</li>
                <li>Upon termination: all rights granted to you under this Agreement will also terminate; and you must cease all use of the application. Termination will not limit any of Aware24 Ltd. application’ rights or remedies at law or in equity.</li>
              </ol>
              <br/>
            </li>

            <li>
              Disclaimer of Warranties.<br/>
              The application is provided to licensee "as is" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Aware24 Ltd. application, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the application, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage, or trade practice. Without limitation to the foregoing, Aware24 Ltd. application provides no warranty or undertaking, and makes no representation of any kind that the application will meet your requirements, achieve any intended results, be compatible, or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards, or be error-free, or that any errors or defects can or will be corrected.
              <br/>
              <br/>
            </li>

            <li>
              Limitation of liability.<br/>
              To the fullest extent permitted by applicable law, in no event will any affiliate of the Aware24 Ltd. company, or any of its or their respective licensors or service providers, have any liability arising from or related to your use of or inability to use the application or the content and services for:
              <ol>
                <li>Personal injury, property damage, lost profits, cost of substitute goods or services, loss of data, loss of goodwill, business interruption, computer failure or malfunction, or any other consequential, incidental, indirect, exemplary, special, or punitive damages.</li>
                <li>Direct damages in amounts that in the aggregate exceed the amount actually paid by you for the application.</li>
              </ol>
              The foregoing limitations will apply whether such damages arise out of breach of contract, tort (including negligence), or otherwise and regardless of whether such damages were foreseeable or Aware24 Ltd. application was advised of the possibility of such damages. some jurisdictions do not allow certain limitations of liability so some or all of the above limitations of liability may not apply to you.
              <br/>
              <br/>
            </li>

            <li>
              Governing Law and Dispute Resolution.<br/>
              These Terms are governed by the laws of the country specified in the Membership Agreement that makes you an eligible user of the mobile services. Any dispute arising out of or in connection with these Terms and Conditions, including any question regarding its existence, validity or termination, shall be referred to and finally resolved pursuant to the dispute resolution clause specified in the Membership Agreement. If there is no applicable clause or valid Membership Agreement, these Terms and Conditions shall be governed by and construed in accordance with the laws of Hong Kong and any dispute shall be referred to and finally resolved by arbitration in Hong Kong. The language to be used in the arbitral proceedings shall be English.
              <br/>
              <br/>
            </li>

            <li>
              Language.<br/>
              The English language version of this agreement shall prevail over any translated version of this agreement if there is a conflict.
              <br/>
              <br/>
            </li>

            <li>
              Limitation of Time to File Claims.<br/>
              Any cause of action or claim you may have arisen out of or relating to this agreement or the application must be commenced within one (1) year after the cause of action accrues otherwise such cause of action or claim is permanently barred.
              <br/>
              <br/>
            </li>

            <li>
              Entire Agreement.<br/>
              These Terms our Privacy Policy and the Membership Agreement constitute the entire agreement between you and Aware24 Ltd. application with respect to the application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the application.
              <br/>
              <br/>
            </li>

            <li>
              Waiver<br/>
              No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between these Terms and any applicable purchase or other terms, these terms shall govern.
              <br/>
              <br/>
            </li>
          </ol>
          `);
  }

  get acknowledgement(): TranslateResult {
    return this
      .$t(`I acknowledge that I have read, understood and accepted the <span class="primary--text">
            Terms and Conditions of Use</span> and the <span class="primary--text">Terms and Conditions of Sale</span>`);
  }

  get agreedOnTerms(): boolean {
    return Boolean(this.store.signupState.agreedOnTerms);
  }

  set agreedOnTerms(agreedOnTerms: boolean) {
    this.store.updateState({ agreedOnTerms });
  }

  get nextButtonLabel(): TranslateResult {
    return this.corporateAccountFlow
      ? this.$t("Register")
      : this.$t("Proceed to checkout");
  }

  get corporateAccountFlow(): boolean {
    return this.$route.params.type === "corporate";
  }

  async handleNext(): Promise<void> {
    this.processing = true;
    this.error = false;

    if (this.isCorporateAccountFlow) {
      const result: ActivateAccountResult = await SignupApi.activateAccount(
        this.store.signupState
      );

      if (result.result) {
        this.$emit("complete");
      } else {
        this.error = true;
      }
    } else {
      this.$emit("next");
    }

    this.processing = false;
  }

  get isCorporateAccountFlow(): boolean {
    return this.$route.params.type === "corporate";
  }
}
