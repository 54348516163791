







































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SignupApi from "../../services/signup-api";
import SignupStoreModule from "../../store/modules/signup";
import CodeInputField from "../common/code-input-field.vue";
import { ConfirmSignupResult } from "../../models/confirm-signup-result";
import { TranslateResult } from "vue-i18n";

const VERIFICATION_CODE_SIZE = 6;
//const EMAIL_METHOD = "EMAIL";

const RESEND_TIMEOUT = 5000;
const RESEND_TICK_TIMEOUT = 1000;

@Component({
  components: {
    CodeInputField,
  },

  data() {
    return {
      VERIFICATION_CODE_SIZE,
    };
  },
})
export default class AccountVerificationSection extends Vue {
  store: SignupStoreModule = getModule(SignupStoreModule, this.$store);
  invalidCode = false;
  processing = false;
  resendBlockTime = 0;

  get verificationCode(): string {
    return this.store.signupState.verificationCode || "";
  }

  set verificationCode(verificationCode: string) {
    this.store.updateState({ verificationCode });
  }

  validVerificationCode(v: string): TranslateResult {
    if (!v) return this.$t("Verification code is required.");

    return "";
  }

  get complete(): boolean {
    return this.verificationCode.length === VERIFICATION_CODE_SIZE;
  }

  get isEmailMethod(): boolean {
    //return this.store.signupState.verificationMethod === EMAIL_METHOD;
    return true;
  }

  get instruction(): TranslateResult {
    return this.isEmailMethod
      ? this.$t(
          "A verification code has been sent by email. Check your inbox and type the verification code below."
        )
      : this.$t(
          "A verification code has been sent to your phone number. Check your inbox and type the verification code below."
        );
  }

  get errorMessage(): TranslateResult {
    return this.isEmailMethod
      ? this.$t(
          "The code you entered is incorrect. Verify the 6 digit-code that was sent to you by email at this address {email} and try again.",
          { email: this.store.signupState.email }
        )
      : this.$t(
          "The code you entered is incorrect. Verify the 6 digit-code that was sent to you by text at this number {phone} and try again.",
          { phone: this.store.signupState.members[0].phone }
        );
  }

  get isConfirmed(): boolean {
    return Boolean(this.store.signupState.confirmed);
  }

  async submit(): Promise<void> {
    this.invalidCode = false;
    this.processing = true;
    try {
      const result: ConfirmSignupResult = await SignupApi.confirmRegistration(
        this.store.signupState
      );

      if (result.success) {
        this.store.setConfirmedSignup(true);
        this.$emit("next");
      } else {
        this.invalidCode = true;
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      this.processing = false;
    }
  }

  resend(): void {
    this.resendBlockTime = RESEND_TIMEOUT;
    SignupApi.resendConfirmation(this.store.signupState);
    this.verificationCode = "";
    this.invalidCode = false;
    this.tickResendTimeout();
  }

  tickResendTimeout(): void {
    setTimeout(() => {
      this.resendBlockTime -= RESEND_TICK_TIMEOUT;

      if (this.resendBlockTime) {
        this.tickResendTimeout();
      }
    }, RESEND_TICK_TIMEOUT);
  }
}
